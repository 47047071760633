<template>
    <div class="form-group">
        <label for="autocomplete">
            Selecione sua localizaçao
        </label>
        <div class="input-group mb-3">
            <gmap-autocomplete
                id="autocomplete"
                :placeholder="address"
                @place_changed="setPlace" class="form-control"
                :options="{
                    country:[
                        'br'
                    ]
                }">
            </gmap-autocomplete>
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" @click="locatorButtonPressed"><i class="fas fa-crosshairs" :class="{'fa-spin':spinner}"></i></button>
            </div>
        </div>
        <div class="alert alert-danger" v-show="erros" v-text="erros">
        </div>
        <input type="hidden" name="address" v-model="address">
        <input type="hidden" name="latitude" v-model="latitude">
        <input type="hidden" name="longitude" v-model="longitude">
    </div>
</template>

<script>
export default {
    name: "GMapLocator",
    data() {
        return {
            erros:'',
            address:"",
            latitude:"",
            longitude:"",
            spinner:false,
        }
    },
    computed:{
    },
    mounted() {

        },
    methods:{
        locatorButtonPressed()
        {
            this.spinner = true;
            if(navigator.geolocation)
            {
                navigator.geolocation.getCurrentPosition(position => {
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;
                    this.decodeLatLong();
                },error=>{
                    this.erros = "Não conseguimos encontrar sua localização, por favor a entre manualmente.";
                    this.spinner = false;
                });
            }else
            {
                this.erros = "Não conseguimos encontrar sua localização, por favor a entre manualmente.";
            }
        },
        decodeLatLong () {
            this.$gmapApiPromiseLazy().then(() => {
                // eslint-disable-next-line no-undef
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ location: { lat: this.latitude, lng: this.longitude } }, (results, status) => {
                    if (status === 'OK') {
                        this.address = results[0].formatted_address;
                        this.spinner = false;
                    }
                })
            })
        },
        decodeAddress(address)
        {
            this.$gmapApiPromiseLazy().then(() => {
                // eslint-disable-next-line no-undef
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({
                    'address': address
                }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                        this.latitude = results[0].geometry.location.lat();
                        this.longitude = results[0].geometry.location.lng();
                    }
                });
            })
        },
        setPlace(place)
        {
            this.address = place.formatted_address;
            this.decodeAddress(this.address);
        }
    }
}
</script>

<style scoped>

</style>
