import { render, staticRenderFns } from "./BotaoAprovaInscricao.vue?vue&type=template&id=364d4a10&scoped=true&"
import script from "./BotaoAprovaInscricao.vue?vue&type=script&lang=js&"
export * from "./BotaoAprovaInscricao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364d4a10",
  null
  
)

export default component.exports