<template>
    <div class="card mb-2" id="card-fotos">
    <div class="card-header">
        <div class="form-group">
            <h6>Selecione as fotos</h6>
            <input type="file" id="fotoInput" multiple name="fotos[]" @change="onFileChange" ref="inputFoto">
            <button @click="removeImage">Limpar</button>
        </div>
    </div>
    <div class=" card-body row">
        <div v-for="foto in fotos" class="col-2">
            <img :src="foto" class="img-thumbnail">
        </div>
    </div>
    </div>
</template>

<script>

export default {
    name: "CardFotos",
    mounted() {
        if(this.prop_fotos.length > 0)
        {
            this.fotos = this.prop_fotos
        }
    },
    data() {
        return {
            fotos : [],
        }
    },
    props : ['prop_fotos'],
    methods: {
        onFileChange(e) {
            e.preventDefault();
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
            {
                return;
            }
            else
            {
                for (var i = 0; i < files.length; i++) {
                    this.createImage(files[i]);
                }

            }
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.fotos.push(e.target.result);
            };
            reader.readAsDataURL(file);
        },
        removeImage: function (e) {
            e.preventDefault();
            this.fotos = '';
            this.$refs.inputFoto.value='';
        },
    },
}
</script>

<style scoped>

</style>
