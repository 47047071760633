<template>
    <div class="mb-2">
        <gmap-map
            :center="{ lat: this.center_lat,
                lng: this.center_lng}"
            :options="{
        clickableIcons: false,
        streetViewControl: true,
        panControlOptions: false,
        gestureHandling: 'cooperative',
        mapTypeControl: false,
        zoomControlOptions: {
          style: 'SMALL'
        },
        zoom: 14}"
            style="width: 100%; height: 440px;"
            @click="handleMapClick">
            <gmap-marker :position="getPosition"
                         :clickable="true"
                         :draggable="true"
            >
            </gmap-marker>
        </gmap-map>
        <input type="hidden" name="loja[latitude]" v-model="lat">
        <input type="hidden" name="loja[longitude]" v-model="lng">
        <input type="hidden" name="loja[address]" v-model="address">
    </div>
</template>

<script>
export default {
    name: "GMapLoja",
    props:{
        'prop_lat':String,
        'prop_lng':String,
        'prop_address':String
    },
    mounted() {
        this.lat = parseFloat(this.prop_lat);
        this.center_lat = parseFloat(this.prop_lat);
        this.lng = parseFloat(this.prop_lng);
        this.center_lng = parseFloat(this.prop_lng);
        this.address = this.prop_address;
    },
    data() {
        return {
            lat : 0,
            lng : 0,
            center_lat:0,
            center_lng:0,
            address:''
        }
    },
    computed:{
        mapCenter()
        {
            return {
                lat: this.center_lat,
                lng: this.center_lng
            }
        },
        getPosition(){
            return {
                lat:this.lat,
                lng:this.lng
            }
        },
    },
    methods:{
        handleMapClick(e)
        {
            this.lat = e.latLng.lat();
            this.lng = e.latLng.lng();
            //set adress
            this.decodeLatLong();
        },
        decodeLatLong () {
            this.$gmapApiPromiseLazy().then(() => {
                // eslint-disable-next-line no-undef
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ location: { lat: this.lat, lng: this.lng } }, (results, status) => {
                    if (status === 'OK') {
                        this.address = results[0].formatted_address;
                    }
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
