var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('gmap-map',{staticStyle:{"width":"100%","height":"440px"},attrs:{"center":{ lat: this.center_lat,
            lng: this.center_lng},"options":{
    clickableIcons: false,
    streetViewControl: true,
    panControlOptions: false,
    gestureHandling: 'cooperative',
    mapTypeControl: false,
    zoomControlOptions: {
      style: 'SMALL'
    },
    zoom: 14}},on:{"click":_vm.handleMapClick}},[_c('gmap-marker',{attrs:{"position":_vm.getPosition,"clickable":true,"draggable":true}})],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lat),expression:"lat"}],attrs:{"type":"hidden","name":"loja[latitude]"},domProps:{"value":(_vm.lat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lat=$event.target.value}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lng),expression:"lng"}],attrs:{"type":"hidden","name":"loja[longitude]"},domProps:{"value":(_vm.lng)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lng=$event.target.value}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],attrs:{"type":"hidden","name":"loja[address]"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }