<template>
    <div class="mb-2">
        <input v-model="status" type="hidden" value="P" id="status" name="inscricao[status]">
        <button v-on:click="aprovar" class="btn btn-success" type="submit">Aprovar</button>
        <button v-on:click="rejeitar" class="btn btn-danger" type="submit">Rejeitar</button>
    </div>
</template>

<script>
export default {
    name: "BotaoAprovaInscricao",
    data(){
        return {
            status:'P'
        }
    },
    methods:{
        aprovar: function (event) {
            this.status = 'A';
        },
        rejeitar: function (event) {
            this.status = 'R';
        }
    }

}
</script>

<style scoped>

</style>
