/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
require('./enable-popovers');
window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
import Lingallery from 'lingallery';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps,{
    load:{
        key:'AIzaSyBldnNcD4KfOcD3y0BVxE_QrbIIGUoVfn0',
        libraries: 'places,directions',
        region: 'BR',
        language: 'pt',
    }
});

Vue.component('lingallery', Lingallery);
Vue.component('card-fotos', require('./components/CardFotos.vue').default);
Vue.component('anuncio-combo', require('./components/AnuncioCombo.vue').default);
Vue.component('botao-aprova-inscricao', require('./components/BotaoAprovaInscricao.vue').default);
Vue.component('carossel-input', require('./components/CarrosselFotos.vue').default);
Vue.component('gmap-loja', require('./components/GMapLoja.vue').default);
Vue.component('gmap-locator', require('./components/GMapLocator.vue').default);
Vue.component('gmap-directions-loja', require('./components/GMapDirectionsLoja').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
