<template>
    <div class="m-1">
        <div class="d-flex flex-row m-1">
            <button @click="toogleMapa" class="btn-primary rounded-pill">
                Carregar Mapa
            </button>
            <button @click="toogleRota" class="btn btn-success rounded-pill" :disabled="!this.mapaAtivo">
                Rota
            </button>
        </div>

        <GmapMap ref="gmap" v-if="this.mapaAtivo"
            :center="this.center"
         :options="{
        clickableIcons: true,
        streetViewControl: false,
        panControlOptions: false,
        gestureHandling: 'cooperative',
        mapTypeControl: false,
        zoomControlOptions: {
          style: 'SMALL'
        },
        zoom: 14}">
            <gmap-marker :position="this.start_marker"
                         :clickable="true"
                         v-if="!rota"
            >
            </gmap-marker>
            <gmap-marker :position="this.end_marker"
                         :clickable="true"
                         v-if="!rota"
            >
            </gmap-marker>
            <DirectionsRenderer travelMode="DRIVING" :origin="origin" :destination="destination"/>
        </GmapMap>

    </div>
</template>

<script>
import DirectionsRenderer from "./DirectionsRenderer";
import {gmapApi} from 'vue2-google-maps';

export default {
    components: {
        DirectionsRenderer
    },
    props: {
        start_lat: Number,
        start_lng: Number,
        end_lat: Number,
        end_lng: Number,
    },
    afterCreate()
    {

    },
    mounted() {

    },
    data()
    {
        return {
            mapaAtivo:false,
            rota:false,
        }
    }
    ,

    computed: {
        google: gmapApi,
        origin() {
            if (!this.rota) return null;
            return { lat:this.start_lat,lng:this.start_lng };
        },
        destination() {
            if (!this.rota) return null;
            return { lat:this.end_lat,lng:this.end_lng };
        },
        center()
        {
            return {
                lat:this.start_lat,
                lng:this.start_lng
            }
        },
        start_marker()
        {
            return {
                lat:this.start_lat,
                lng:this.start_lng
            }
        },
        end_marker()
        {
            return {
                lat:this.end_lat,
                lng:this.end_lng
            }
        },

    },
    methods:
    {
        toogleRota()
        {
            this.rota = !this.rota
        },
        toogleMapa()
        {
            this.mapaAtivo = !this.mapaAtivo
        }
    }
};
</script>

<style>
.vue-map-container {
    height: 300px;
}
</style>
