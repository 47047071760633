<template>
    <div class="card mb-2" id="card-fotos">
    <div class="card-header">
        <div class="form-group">
            <h6>Selecione a foto</h6>
            <input class="form-control-file mb-1" type="file" id="fotoInput" multiple name="foto[foto]" @change="onFileChange" ref="inputFoto" accept="image/*">
            <button class="btn btn-danger" @click="removeImage">Limpar</button>
        </div>
    </div>
    <div class="card-body row">
        <img :src="foto" v-if="foto">
        <p v-else>Sem imagem...</p>
    </div>
    </div>
</template>

<script>

export default {
    name: "CarrosselFotos",
    mounted() {

    },
    data() {
        return {
            foto : '',
        }
    },
    methods: {
        onFileChange(e) {
            e.preventDefault();
            var file = event.target.files[0];
            if (!file) {
                return false
            }
            if (!file.type.match('image.*')) {
                return false
            }
            this.createImage(file);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.foto = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeImage: function (e) {
            e.preventDefault();
            this.foto = '';
            this.$refs.inputFoto.value='';
        },
    },
}
</script>

<style scoped>

</style>
