<template>
    <div id="vue">
        <div class="mb-2 form-group">
            <label for="franquias_id">Franquia</label>
            <select name="franquias_id" class="form-control" id="franquias_id" @change="buscaLoja" v-model="selected">
                <option v-for="f in franquias" :value="f.value">
                    {{ f.text }}
                </option>
            </select>
        </div>
        <div class="mb-2 form-group">
            <label for="loja_id"><span class="label-required">*</span> Loja</label>
            <select name="anuncio[loja_id]" class="form-control" id="loja_id">
                <option v-for="loja in lojas" v-bind:value="loja.id"> {{ loja.descricao }}</option>
            </select>
        </div>
        <div v-if="loja_id_erro" class="alert alert-danger">{{loja_id_erro}}</div>
    </div>
</template>

<script>
export default {
    name: "AnuncioCombo",
    props:[
        'prop_franquias',
        'loja_id_erro',
        'franquias_id_erro'],
    mounted() {
        this.franquias = JSON.parse(this.prop_franquias);
    },
    data(){
        return {
            lojas:[],
            selected: '',
            franquias:[]
        }
    },
    methods: {
        buscaLoja()
        {
            axios.get('/interno/loadLojasCombo/'+this.selected,{
                headers : {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            })
                .then((response) => {
                    console.log(response.data);
                    this.lojas=response.data;
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>

</style>
